.App {
  font-family: "Raleway", sans-serif;
  background-color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  box-sizing: border-box;
  height: calc(100vh - var(--browser-offset, 0px)); /* Adjusted height */
  width: 100vw;
}

.saturation {
  filter: saturate(0%);
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #080808;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.5s linear infinite;
}

.dark-loader {
  border: 3px solid #080808;
  border-top: 3px solid #f3f3f3;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sign-up-form-container {
  min-height: 80%;
  height: auto;
}

.custom-outline-xyz-o {
  box-shadow: 0px -30px 50px 3px white;
}

.universal-button {
  background-color: #022022;
  box-shadow: inset 3px 3px 10px #607e80 !important; /* Shadow on top-left */
}