#container-circles {
    position: absolute;
    left: 5% !important;
    top: 8% !important;
    height: 84% !important;
    width: 90% !important;
    /* border: 2px solid red; */
    z-index: 3;
}

#outer-circle {
    position: relative !important;
    left: 0px !important;
    border-radius: 0% !important;
    width: 100% !important;
    height: 100% !important;
    background-color: transparent !important;
    /* border: 2px solid blue; */
}

#inner-circle {
    position: relative !important;
    top: 0% !important;
    left: 0% !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0% !important;
    margin: 0px !important;
    background-color: transparent !important;
    /* border: 2px solid white; */
}

.camera-top-layer-2 {
    z-index: 2;
}

.ovule-container {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100vh;
    z-index: 1;
}

.ovule {
    border: 4px solid rgba(0, 153, 255, 1);
    outline: 550px solid rgba(0, 0, 0, 0.6);
}

.ovule-0 {
    width: 300px;
    height: 160px;
    border-radius: 40%;
}

.ovule-1 {
    width: 280px;
    height: 200px;
    border-radius: 60% 60% 10% 10%;
}

.ovule-4-reversed {
    width: 280px;
    height: 200px;
    border-radius: 10% 10% 60% 60%;
}

.ovule-2 {
    width: 275px;
    height: 200px;
    border-radius: 10% 10% 60% 60%;
}

.ovule-3,
.ovule-4 {
    width: 90%;
    height: 180px;
    border-radius: 40%;
}

.camera-error-popup {
    z-index: 4;
}

.flash-container {
    z-index: 3;
}

#display-error {
    display: none;
}

.WebCam video {
    width: 100%;
    height: 100%;
    object-fit: cover !important; /* Ensures the video covers the container without stretching */
}

.WebCam {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
