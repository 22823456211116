#container-circles {
    position: absolute;
    left: 5% !important;
    top: 8% !important;
    height: 84% !important;
    width: 90% !important;
    /* border: 2px solid red; */
    z-index: 3;
}
#outer-circle {
    position: relative !important;
    left: 0px !important;
    border-radius: 0% !important;
    width: 100% !important;
    height: 100% !important;
    background-color: transparent !important;
    /* border: 2px solid blue; */
}
#inner-circle {
    position: relative !important;
    top: 0% !important;
    left: 0% !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0% !important;
    margin: 0px !important;
    background-color: transparent !important;
    /* border: 2px solid white; */
}
.camera-top-layer-2 {
    z-index: 2;
}
.ovule-container {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100vh;
    z-index: 1;
}

.camera-error-popup {
    z-index: 4;
}
.flash-container {
    z-index: 3;
}
#display-error {
    display: none;
}
.full-preview-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(253, 252, 252, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    /* Make sure it is above other elements */
}
.full-preview-content {
    max-width: 60%;
    /* Limit max width of the image */
    min-height: 60%;
    /* Limit max height of the image */
}
.full-preview-content img {
    width: 100%;
    height: 100%;
}
.image-slider-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    /* Adjust based on your image size */
    margin: 0 auto;
}
.image-container {
    position: relative;
    width: 100%;
    height: auto;
}
.background-image,
.foreground-image {
    width: 100%;
    min-height: 50vh;
    display: block;
}
.foreground-image {
    position: absolute;
    top: 0;
    left: 0;
    clip-path: inset(0 50% 0 0);
    /* Initial clip */
}
.slider-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
.slider {
    width: 100%;
    margin: 0;
    appearance: none;
    background: transparent;
}
/* Track before and after the thumb (WebKit Browsers - Chrome, Safari, Edge) */
.slider::-webkit-slider-runnable-track {
    width: 100%;
    height: 7px;
    /* border-radius: 5px; */
    background: linear-gradient(to right, #022022 0%, #022022 var(--progress), #dce0e0 var(--progress), #dce0e0 100%);
}
/* Track before and after the thumb (Firefox) */
.slider::-moz-range-track {
    width: 100%;
    height: 7px;
    border-radius: 5px;
    background: linear-gradient(to right, #022022 0%, #022022 var(--progress), #dce0e0 var(--progress), #dce0e0 100%);
}
/* Thumb Styling */
.slider::-webkit-slider-thumb {
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
    background-image: url("../../../assets/simmetry/Knob.png");
    background-size: cover;
    background-position: center;
    margin-top: -15px;
    transition: box-shadow 0.2s ease;
}
.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    appearance: none;
    background-image: url("../../../assets/simmetry/Knob.png");
    background-size: cover;
    background-position: center;
    transition: box-shadow 0.2s ease;
}
/* Vertical Border Indicator */
.foreground-image-border {
    position: absolute;
    top: 0;
    left: var(--progress);
    width: 2px;
    height: 100%;
    background: rgb(248, 247, 247);
    z-index: 10;
}
iframe {
    width: 100%;
    height: 100vh;
    border: none;
}
/* Optional: center the iframe container */
#iframe-container {
    text-align: center;
    margin-top: 20px;
}

.WebCam video {
    width: 100%;
    height: 100%;
    object-fit: cover !important; /* Ensures the video covers the container without stretching */
}

.WebCam {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}
